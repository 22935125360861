import audi from './audi';
import seat from './seat';
import cupra from './cupra';
import vw from './vw';
import vwn from './vwlc';
import { LocalBrands, LocalLanguages } from '../shared/types';


type AppConfig = {
  brandName: LocalBrands;
  language: LocalLanguages;
  isCarTypeSelectionEnabled: boolean;
};

const defaultBrand: LocalBrands = 'audi';

const configAllBrands = {
  audi,
  seat,
  cupra,
  vw,
  vwn
} as Record<LocalBrands, { appConfig: AppConfig }>;

export const appConfig =
  configAllBrands[
    process.env.REACT_APP_BRAND
      ? (process.env.REACT_APP_BRAND as keyof typeof configAllBrands)
      : defaultBrand
  ].appConfig;

const chooseVwBrand = (brand: string): string => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel';
    case 'vw':
    case 'vw6':
    case 'vwn':
      return 'vw6';
    default:
      return brand;
  }
};

export const getStyles = (brandName: string) => {
  return import(
    `@vwfs-bronson/bronson-${
      brandName.includes('vw') ? chooseVwBrand(brandName) : brandName
    }/dist/css/style.min.css`
  );
};
